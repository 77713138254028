<script setup>
  import { onMounted  } from 'vue';
  import { storeToRefs } from 'pinia';
  import useAuthStore from '@/stores/auth.store';
  import useModuleStore from '@/stores/module.store';

  const authStore = useAuthStore();
  const { email, id_user, token } = storeToRefs(authStore);
  const param = `?email=${email.value}&token=${token.value}`;

  const moduleStore = useModuleStore();
  const { modules } = storeToRefs(moduleStore);

  async function handleLogout() {
    await authStore.logout(id_user.value);
  }

  onMounted(() => {
    moduleStore.getModules();
});
</script>

<script>
  export default {
    name: 'PageHome'
  }
</script>

<template>
  <nav class="navbar fixed-top navbar-light bg-white shadow-header">
    <div class="container">
      <div class="navbar-brand">
        <h3>UI Portal</h3>
      </div>
      <button type="button" @click="handleLogout" class="btn btn-sm btn-outline-dark btn-logout px-3 rounded-25">
        <i class="fa fa-sign-out pr-1"></i>
        Logout
      </button>
    </div>
  </nav>

  <div class="right-image-decor"></div>

  <section class="section" id="about">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12" v-for="(module, index) in modules" v-bind:key="index"
          data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
          <div class="features-item mb-4">
            <div class="features-icon">
              <img :src="require(`@/assets/images/${module.icon}`)" :alt="module.name">
              <h4>{{ module.name }}</h4>
              <!-- <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis, architecto.</p> -->
              <a :href="module.url_api_login + param" class="main-button">Kunjungi</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ***** Features Big Item End ***** -->

  <!-- ***** Footer Start ***** -->
  <footer id="contact-us">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="sub-footer">
            <p class="text-dark">Copyright &copy; 2024 PT Universal Indonesia Mobile</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
