import { defineStore } from "pinia";
import useAlertStore from "./alert.store";
import axios from "axios";
import { getCache } from "@/helpers/local-storage";

const apiUrl = "https://api-uat-portal.uimobile.net:8443";

const useModuleStore = defineStore({
  id: "modules",
  state: () => ({
    modules: [],
  }),
  actions: {
    async getModules() {
      const alertStore = useAlertStore();
      try {
        const response = await axios.get(
          `${apiUrl}/api/web/modules/${getCache("id_user")}`
        );

        this.modules = response.data.responseData;
      } catch (error) {
        if (error.response) {
          const { data } = error.response;
          alertStore.showToast(data.responseMessage, "error");
        } else {
          alertStore.showToast(error.message, "error");
        }
      }
    },
  },
});

export default useModuleStore;
