import { clearCache, getCache, setCache } from "@/helpers/local-storage";
import getUserAgentInfo from "@/helpers/user-agent";
import router from "@/router";
import useAlertStore from "@/stores/alert.store";
import axios from "axios";
import { defineStore } from "pinia";

const userAgent = getUserAgentInfo();
const apiUrl = "https://api-uat-portal.uimobile.net:8443";

const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    id_user: getCache("id_user"),
    email: getCache("email"),
    token: getCache("token"),
  }),
  actions: {
    async login(email_user, password) {
      const alertStore = useAlertStore();

      try {
        const response = await axios.post(`${apiUrl}/authenticate/web/login`, {
          ...userAgent,
          email: email_user,
          password,
        });

        const randomString = Math.random().toString(36).substring(2, 12);
        const token = window.btoa(randomString + password);
        const { id, email } = response.data.responseData;

        // update auth state
        this.id_user = id;
        this.email = email;
        this.token = token;

        // store to local storage
        setCache("id_user", id);
        setCache("email", email);
        setCache("token", token);

        router.push("/");

        alertStore.showToast(response.data.responseMessage, "success");
      } catch (error) {
        if (error.response) {
          const { data } = error.response;
          alertStore.showToast(data.responseMessage, "error");
        } else {
          alertStore.showToast(error.message, "error");
        }
      }
    },
    async logout(idUser) {
      const alertStore = useAlertStore();

      try {
        const response = await axios.post(`${apiUrl}/authenticate/web/logout`, {
          id_user: idUser,
        });

        const { responseMessage } = response.data;

        this.id_user = null;
        this.email = null;
        clearCache();

        router.push("/login");

        alertStore.showToast(responseMessage, "success");
      } catch (error) {
        if (error.response) {
          const { data } = error.response;
          alertStore.showToast(data.responseMessage, "error");
        } else {
          alertStore.showToast(error.message, "error");
        }
      }
    },
  },
});

export default useAuthStore;
