import LoginForm from "@/views/LoginForm.vue";
import Home from "@/views/Home.vue";
import { createRouter, createWebHistory } from "vue-router";
import useAuthStore from "@/stores/auth.store";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/login", component: LoginForm },
    { path: "/", component: Home },
    // catch all redirect to home page
    { path: "/:pathMatch(.*)*", redirect: "/" },
  ],
});

router.beforeEach(async (to) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();

  // redirect to login page if not logged in and trying to access a restricted page
  if (authRequired && !authStore.email) {
    return "/login";
  }

  if (authStore.email != null) {
    // redirect to Home page if logged in
    if (to.fullPath == "/login" && authStore.email) {
      to.fullPath = "/";
      return to.fullPath;
    }
  }
});

export default router;
