<script setup>
  import useAuthStore from '@/stores/auth.store';
  import { Form, Field } from 'vee-validate';
  import * as Yup from 'yup';

  const authStore = useAuthStore();

  const schema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Must be a valid email'),
    password: Yup.string().required('Password is required')
  });

  async function onSubmit(values) {
    const { email, password } = values;
    await authStore.login(email, password);
  }
</script>

<script>
  export default {
    name: 'LoginForm',
    data () {
      return {
        showPassword: false,
        email:'',
        password:''
      }
    },
  }
</script>

<template>
  <footer class="mt-0 pt-0 min-h-100vh">
    <div class="container d-flex align-items-center min-h-100vh">
      <div class="footer-content">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="contact-form">
              <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
                <div class="row">
                  <div class="col-12">
                    <h3 class="pb-4 font-weight-600">Welcome to UI Portal</h3>
                  </div>
                  <div class="col-12 mb-3">
                    <Field name="email" type="email" placeholder="Email" class="form-control mb-0" :class="{ 'is-invalid': errors.email }" v-model="email" />
                    <div class="invalid-feedback text-left">{{ errors.email }}</div>
                  </div>
                  <div class="col-12">
                    <div class="input-group mb-3">
                      <Field v-bind:type="[showPassword ? 'text' : 'password']" class="form-control" placeholder="Password"
                        name="password" :class="{ 'is-invalid': errors.password }" v-model="password" autocomplete="off" />
                      <div class="input-group-append">
                        <span class="input-group-text eye">
                          <i @click="showPassword = !showPassword" class="fa" :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"></i>
                        </span>
                      </div>
                      <div class="invalid-feedback text-left">{{ errors.password }}</div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button id="form-submit" class="main-button" :disabled="isSubmitting">
                      <span v-show="isSubmitting" class="btn-warning-text spinner-border spinner-border-sm mr-1"></span>
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="right-content col-lg-6 col-md-12 col-sm-12">
            <h2><em>UI</em> Portal</h2>
            <p>UI Mobile adalah pintu digital bagi seluruh civitas akademika UI. Solusi lengkap yang menyatukan berbagai macam informasi dalam satu wadah, baik siklus kehidupan akademis maupun non-akademis</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
