import Bowser from "bowser";

async function getIpAddress() {
  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();
  const { ip } = data;
  return ip;
}

const ipAddress = await getIpAddress();

const getUserAgentInfo = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  return {
    browser_type: browser.getBrowserName(),
    browser_version: browser.getBrowserVersion(),
    browser_os: browser.getOSName(),
    ip_address: ipAddress
  };
};

export default getUserAgentInfo;
