import { defineStore } from "pinia";
import { useToast } from "vue-toast-notification";

const $toast = useToast();

const useAlertStore = defineStore({
  id: "alert",
  state: () => ({
    alert: null,
  }),
  actions: {
    showToast(message, type) {
      this.alert = $toast.open({
        position: "top",
        duration: 3500,
        dismissible: false,
        message,
        type,
      });
    },
  },
});

export default useAlertStore;
